export const EDUCATION = [
  "",
  "Less Than HS Diploma",
  "High School Diploma",
  "Some College, No Degree",
  "College Certificate(s)",
  "Associate's Degree",
  "Bachelor's Degree",
  "Master's Degree",
  "Doctoral Degree",
];
